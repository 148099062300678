import React from 'react'

const Events = () => {
    return (
        <div>
            <h1 style={{textAlign:"center"}}>The is no Event </h1>
        </div>
    )
}

export default Events
